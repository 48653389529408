@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
  --light: #f6f6f9;
  --primary: #1976d2;
  --light-primary: #5da8ee;
  --sidebar-bg: #4961c9;
  --tb-header:#abcaf5;
  --grey: #eee;
  --tb-body: #e2e2e2;
  --dark-grey: #aaaaaa;
  --dark: #363949;
  --danger: #d32f2f;
  --light-danger: #fecdd3;
  --warning: #fbc02d;
  --light-warning: #fff2c6;
  --success: #388e3c;
  --light-success: #bbf7d0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.bx {
  font-size: 1.7rem;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

html {
  overflow-x: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Body styles */
body {
  background: var(--grey);
  overflow-x: hidden;
}

body.dark {
  --light: #181a1e;
  --grey: #25252c;
  --dark: #fbfbfb;
}

/* Content styles */
.content {
  position: relative;
  width: calc(100% - 230px);
  left: 230px;
  transition: all 0.3s ease;
}

.content nav {
  height: 56px;
  background: var(--light);
  padding: 0 24px 0 0;
  display: flex;
  align-items: center;
  grid-gap: 24px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
}

.content nav::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -40px;
  left: 0;
  border-radius: 50%;
  box-shadow: -20px -20px 0 var(--light);
}

.content nav a {
  color: var(--dark);
}

.content nav .bx.bx-menu {
  cursor: pointer;
  color: var(--dark);
}

.content nav .bx.bx-cog {
  cursor: pointer;
  color: var(--dark);
}

.content nav form {
  max-width: 400px;
  width: 100%;
  margin-right: auto;
}

.content nav form .form-input {
  display: flex;
  align-items: center;
  height: 36px;
}

.content nav form .form-input input {
  flex-grow: 1;
  padding: 0 16px;
  height: 100%;
  border: none;
  background: var(--grey);
  border-radius: 36px 0 0 36px;
  outline: none;
  width: 100%;
  color: var(--dark);
}

.content nav form .form-input button {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  color: var(--light);
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 0 36px 36px 0;
  cursor: pointer;
}

.content nav .theme-toggle {
  display: block;
  min-width: 50px;
  height: 25px;
  background: var(--grey);
  cursor: pointer;
  position: absolute; /* Position the toggle absolutely within the nav */
  right: 25px; /* Align to the right side of the nav */
  border-radius: 25px;
}

.content nav .theme-toggle::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: calc(25px - 4px);
  background: var(--primary);
  border-radius: 50%;
  transition: all 0.3s ease;
}

.content nav #theme-toggle:checked + .theme-toggle::before {
  left: calc(100% - (25px - 4px) - 2px);
}

.content main {
  width: 100%;
  padding: 36px 24px;
  max-height: calc(100vh - 56px);
}

.content main .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  flex-wrap: wrap;
}

.content main .header .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--dark);
}

.header .search-bar {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  flex-grow: 1;
  max-width: 200px;
}

.header .search-bar:hover {
  transition: all 0.15s ease;
  background-color: var(--grey);
}

.content main .bottom-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}

.content main .bottom-data > div {
  border-radius: 20px;
  background: var(--light);
  padding: 24px;
  overflow-x: auto;
}

.content main .bottom-data .header {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 24px;
}

.content main .bottom-data .header h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}

.content main .bottom-data .header .bx {
  cursor: pointer;
}

.content main .bottom-data .database-view {
  flex-grow: 1;
  flex-basis: 500px;
}

.content main .bottom-data .database-view table {
  width: 100%;
  border-collapse: collapse;
}

.content main .bottom-data .database-view table th {
  font-size: 13px;
  text-align: center;
  min-width: 25%;
  border-bottom: 1px solid rgb(78, 74, 74);
  padding: 10px 10px 0 10px;
}
#tb-header{
  font-size: 13px;
  text-align: center;
  min-width: 25%;
  border-bottom: 1px solid var(--grey);
  border: 1px solid rgb(78, 74, 74);
  background:var(--tb-header);
  color: #000;
}

.content main .bottom-data .database-view table td {
  border: 1px solid rgb(78, 74, 74);
  padding: 10px;
  text-align: center;
}

.content main .bottom-data .database-view table tr td:first-child {
  /* display: flex;
  align-items: center; */
  /* grid-gap: 12px; */
  /* padding-left: 6px; */
}

.content main .bottom-data .database-view table td img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.content main .bottom-data .database-view table tbody tr {
  cursor: pointer;
  transition: all 0.3s ease;
}

.content main .bottom-data .database-view table tbody tr:hover {
  background: var(--grey);
}

.content main .bottom-data .database-view table tr td .status {
  font-size: 10px;
  padding: 6px 16px;
  color: var(--light);
  border-radius: 20px;
  font-weight: 700;
}

/* Settings styles */
.settings-view {
  flex-grow: 1;
}

.settings-view .theme-options {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 16px;
}

.settings-view .option-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  cursor: pointer;
}

.settings-view .option-container i {
  font-size: inherit;
}

.settings-view .theme-options input,
.settings-view .theme-options label {
  cursor: pointer;
}

.settings-view .change-logo-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.settings-view .file-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  font-size: 20px;
  min-width: 400px;
  cursor: pointer;
}

.settings-view .file-input input {
  font-size: 16px;
  margin-top: -6px;
  margin-bottom: 24px;
}

.settings-view .file-preview img {
  border: 1px solid #000000;
  border-radius: 4px;
  max-height: 240px;
}

.settings-view .file-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-top: 18px;
}

.settings-view .file-buttons button {
  font-size: 16px;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.settings-view .file-buttons button:disabled {
  cursor: not-allowed;
}

/* Modal styles */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s;
}

.modal.open {
  display: flex;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 2px solid #000000;
  width: 80%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.modal-content-header {
  padding: 5px;
  width: 100%;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000000;
}

.modal-content-header h2 {
  margin: auto;
}

.modal-content-body {
  background-color: #fefefe;
  padding: 15px 25px 15px 25px;
  margin: auto;
  overflow-y: auto;
  position: relative;
}

.modal-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 5px; /* Adds spacing between buttons */
}

.modal-button.edit {
  background-color: var(--primary); /* Blue */
  color: white;
  transition: background-color 0.2s ease;
  border-radius: 5px;
}

.modal-button.modal-button.edit:hover {
  background-color: #00649e;
}

.modal-button.delete {
  background-color: var(--danger);
  color: white;
  transition: background-color 0.2s ease;
  border-radius: 5px;
}

.modal-button.delete:hover {
  background-color: #930000;
}

.modal-button:focus {
  outline: none;
}

.refresh-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  cursor: pointer;
  margin: 10px 20px;
  font-size: 18px;
  background-color: transparent;
}

.refresh-button:hover {
  color: var(--success);
}

.switch-button {
  border: 1px solid black;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  font-size: 18px;
  margin-bottom: 20px;
  background-color: transparent;
}

.switch-button:hover {
  background-color: var(--grey);
  transition: background-color 0.3s ease;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

/* Logo styles */
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.logo-name {
  width: 150px;
  height: auto;
}

.logo-name img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Dropdown styles */
.dropdown {
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  z-index: 1000;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: var(--grey);
}

/* Header additional styles */
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.header-container input {
  position: relative;
  bottom: -6px;
}

.header-container button {
  padding: 8px 16px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  flex-grow: 1;
  max-width: 200px;
}

.header-container button:hover,
.header-container button:focus {
  background-color: #ccc;
  transition: background-color 0.3s ease;
  cursor: pointer;
}



body.dark .header-container button {
  padding: 8px 16px;
  color: #fff;
  background-color: #363949;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  flex-grow: 1;
  max-width: 200px;
}

body.dark .header-container button:hover,
body.dark .header-container button:focus {
  background-color: #21242e;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

/* Dark mode styles */
body.dark .close {
  color: #fff;
}

body.dark .close:hover,
body.dark .close:focus {
  color: #ccc;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

body.dark .modal-content-header,
body.dark .modal-content-body {
  color: #ffffff;
  background-color: #363949;
}

input[type="text"],
input[type="date"],
input[type="password"],
select {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="text"]:hover,
input[type="date"]:hover,
input[type="password"]:hover,
input[type="text"]:focus,
input[type="date"]:focus,
input[type="password"]:focus,
select:hover,
select:focus {
  background-color: #f2f2f2;
  transition: background-color 0.2s ease;
}

body.dark input[type="text"],
body.dark input[type="date"],
body.dark input[type="password"],
body.dark select {
  background-color: #2d2f3c;
  color: #ffffff;
  border: 1px solid #ccc;
}

body.dark input[type="text"]:hover,
body.dark input[type="date"]:hover,
body.dark input[type="text"]:focus,
body.dark input[type="date"]:focus,
body.dark input[type="password"]:hover,
body.dark input[type="password"]:focus,
body.dark select:hover,
body.dark select:focus {
  background-color: #21242e;
  color: #ffffff;
  border: 1px solid #ccc;
}

body.dark button {
  color: #ffffff;
}

body.dark .file-buttons button {
  background-color: #ccc;
  color: #000;
}

body.dark .file-buttons button:disabled {
  opacity: 50%;
}

body.dark select.search-bar {
  background-color: #2d2f3c;
  color: #ffffff;
  border: 1px solid #ccc;
}

body.dark select.search-bar:hover,
body.dark select.search-bar:focus {
  background-color: #21242e;
  color: #ffffff;
  border: 1px solid #ccc;
}

body.dark .switch-button {
  border: 1px solid #ccc;
  background-color: #2d2f3c;
}

body.dark .switch-button:hover,
body.dark .switch-button:focus {
  background-color: #21242e;
  transition: background-color 0.2s ease;
}

/* Input Form Styles */
.selectedDocumentType {
  font-style: bold;
  text-transform: uppercase;
}

.link-pointer {
  cursor: pointer;
  color: rgb(4, 0, 231);
  text-decoration: underline;
}

body.dark .link-pointer {
  color: rgb(127, 189, 247);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .content {
    width: calc(100% - 60px);
    left: 200px;
  }
}

@media screen and (max-width: 576px) {
  .content nav form .form-input input {
    display: none;
  }

  .content nav form .form-input button {
    width: auto;
    height: auto;
    background: transparent;
    color: var(--dark);
    border-radius: none;
  }

  .content nav form.show .form-input input {
    display: block;
    width: 100%;
  }

  .content nav form.show .form-input button {
    width: 36px;
    height: 100%;
    color: var(--light);
    background: var(--danger);
    border-radius: 0 36px 36px 0;
  }

  .content main .insights {
    grid-template-columns: 1fr;
  }

  .content main .bottom-data .header {
    min-width: 340px;
  }

  .content main .bottom-data .database-view table {
    min-width: 340px;
  }
}

#un-border{
  border: none;
}