/* Sidebar styles */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  background: var(--sidebar-bg);
  width: 230px;
  height: 100%;
  z-index: 2000;
  overflow-x: hidden;
  scrollbar-width: none;
  transition: all 0.3s ease;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar.close {
  position: fixed;
  top: 0;
  left: 0;
  width: 60px;
  height: 100%;
  z-index: 2000;
  overflow-x: hidden;
  scrollbar-width: none;
  transition: all 0.3s ease;
}

.sidebar .logo {
  font-size: 24px;
  font-weight: 700;
  height: 56px;
  display: flex;
  align-items: center;
  color: var(--primary);
  z-index: 500;
  padding-bottom: 20px;
  box-sizing: content-box;
}

.sidebar .logo .logo-name span {
  color: var(--dark);
}

.sidebar .logo .bx {
  min-width: 60px;
  display: flex;
  justify-content: center;
  font-size: 2.2rem;
}

.sidebar .side-menu {
  width: 100%;
  margin-top: 12px;
}

.sidebar .side-menu li {
  height: 48px;
  background: transparent;
  margin-left: 6px;
  border-radius: 48px 0 0 48px;
  padding: 4px;
}

.sidebar .side-menu li.active {
  background: var(--grey);
  position: relative;
}

.sidebar .side-menu a:hover {
  color: var(--success);
  transition: all 0.3s ease;
}

.sidebar .side-menu li.active::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -40px;
  right: 0;
  box-shadow: 20px 20px 0 var(--grey);
  z-index: -1;
}

.sidebar .side-menu li.active::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  bottom: -40px;
  right: 0;
  box-shadow: 20px -20px 0 var(--grey);
  z-index: -1;
}

.sidebar .side-menu li a {
  width: 100%;
  height: 100%;
  background: var(--light);
  display: flex;
  align-items: center;
  border-radius: 48px;
  font-size: 16px;
  color: var(--dark);
  white-space: nowrap;
  overflow-x: hidden;
  transition: all 0.3s ease;
}

.sidebar .side-menu li a:hover {
  cursor: pointer;
}

.sidebar .side-menu li.active a {
  color: var(--success);
}

.sidebar.close .side-menu li a {
  width: calc(48px - (4px * 2));
  transition: all 0.3s ease;
}

.sidebar .side-menu li a .bx {
  min-width: calc(60px - ((4px + 6px) * 2));
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
}

.sidebar .side-menu li a.logout {
  color: var(--danger);
}

.sidebar.close ~ .content {
  width: calc(100% - 60px);
  left: 60px;
}
