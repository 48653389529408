.table-container {
  overflow-x: auto;
  overflow-y: auto;
}

.document-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.document-table th,
.document-table td {
  border: 1px solid #dddddd;
  padding: 12px;
  text-align: left;
}

.document-table th {
  background-color: #142850;
  color: white;
  font-weight: bold;
  
}

.document-table td {
  vertical-align: top;
}

.document-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.document-table .checklist {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-table .checklist span {
  display: block;
  width: 12px;
  height: 12px;
  border: 1px solid #dddddd;
  border-radius: 50%;
}

.document-table thead th[rowspan="2"] {
  width: 40%;
}

.document-table th[colspan="2"],
.document-table td[colspan="2"] {
  text-align: center;
  background-color: #142850;
  font-weight: normal;
}

@media (max-width: 768px) {
  .document-table th,
  .document-table td {
    padding: 10px;
  }
}
