@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  background-color: #f0f0f0;
}

.login-container {
  display: flex;
  width: 900px;
  height: 600px;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.logo-section {
  width: 50%;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*.logo-header{
  margin-top: 40px;
  align-items: start;
  flex-direction: column;
}
*/
/*.logo-section img{
  margin-top: 210px;
  align-items: center;
  max-width: 80%;
}
*/
.logo-header{
  margin-bottom: 40px;
  align-items: start;
  flex-direction: column;
}

.logo-section img{
  max-width: 80%;
}

.login-section {
  width: 50%;
  background: #142850;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.login-section h1 {
  margin-bottom: 20px;
}
.login-section h2 {
  margin-bottom: 20px;
  text-align: center;
}

.login-section form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.login-section label {
  margin-bottom: 5px;
}

.login-section input[type="text"],
.login-section input[type="password"] {
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.login-section .remember-me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login-section .remember-me input {
  margin-right: 10px;
}

.login-section button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary);
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.login-section button:hover {
  background-color: var(--light-primary);
}

.change-password-container{
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

.change-password-container input[type="password"],.change-password-container input[type="text"] {
  margin-bottom: -10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.change-password-container h3{
  text-align: center;
  margin-bottom: 15px;
}
.change-password-container #btn-back-login{
  width: 50%;
  margin: auto;
}